.container-cabecalho{
    min-height: 95px;
    margin: 0 auto;
    padding:10px;
    /* background-color:rgb(63, 63, 226);
    color: rgb(255, 255, 255); */
    font-size: 12px;
    text-align: center;
    font-family: sans-serif;
}

.container-cabecalho-boletim{
    /* min-height: 95px; */
    margin: 0 auto;
    padding:5px;
    background-color:black;
    color: rgb(255, 255, 255);
    font-size: 12px;
    text-align: center;
    font-family: sans-serif;
}
