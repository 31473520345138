.Logo-4bpm{
    padding: 0;
    width: 64px;
    height: 64px;
    cursor: pointer;
    margin: 0 auto !important;
}

.Logo-4bpm-cabecalho{
    padding: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin: 0 auto !important;
}