.container-spinner{
    width: 40px;
}

.loading__anim {
    width: 35px;
    height: 35px;
    border: 5px solid rgba(189, 189, 189, 0.25);
    border-left-color: rgba(3, 155, 229, 1);
    border-top-color: rgba(3, 155, 229, 1);
    border-radius: 50%;
    display: inline-block;
    animation: rotate 600ms infinite linear;
  }
  
  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }
  